import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { SidebarContext } from 'contexts/SidebarContext';
import { Account } from '../Account';
import AccountCard from '../all/components/AccountCard';
import AboutAccount from 'views/prospects/components/AboutAccount';
import AccountProspectList from '../all/components/ProspectList';
import { GetCollectionItemResponse } from 'views/deals/Deal';

const getCollectionItemUrl = "https://inwise-node-functions1.azurewebsites.net/api/get_collection_item?code=WStSMlvndx_j-8jHmLN_c8PBiVbEpqgB9rwmbwjRprjCAzFuokTAUA%3D%3D";

export default function AccountsOverview() {
  let { accountId } = useParams<{ accountId: string }>();
  const [account, setAccount] = useState<Account>()
  const { setBrandText } = useContext(SidebarContext);

  useEffect(() => {
    if (account) {
      return;
    }
    fetch(getCollectionItemUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO: add JWT
      body: JSON.stringify({
        _id: accountId,
        collectionName: "Account"
      })
    })
      .then(res => res.json())
      .then(data => {
        let { item } = data as GetCollectionItemResponse;
        setAccount(item);
        setBrandText(`Account '${item.name}'`);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);
  if (!account) return null;
  return (
    <Flex pt={{ base: "130px", md: "80px", xl: "80px" }} flexWrap={"wrap"} ml={'-15px'}>
      <Flex
        flexDir='column'
        ml={'15px'}
        mr={"0px"}
        minW={"200px"}
        grow={1}
        shrink={1}
        basis={"200px"} maxW={'25%'}
      >
        <Flex mb={"15px"}>
          <AccountCard account={account} variant={'accountOverview'} minW='200px' w='100%' />
        </Flex>
        <Flex mb={"15px"} h={'100%'} >
          <AccountProspectList accountId={account._id} accountName={account.name} h={'100%'} minW='200px' />
        </Flex>
      </Flex>
      <Flex
        flexDir='column'
        ml={'15px'}
        mr={'0px'}
        minW={"600px"}
        grow={1}
        shrink={1}
        basis={"600px"}
      >
        <Flex mb={"15px"} >
          <AboutAccount account={account} variant={'accountOverview'} />
        </Flex>
      </Flex>
    </Flex>
  );
}
