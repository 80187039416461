import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LocationProps } from 'views/deals/Deal';
import DealsTabContainer from '../dashboard/components/DealsTabContainer';


const getDealsUrl = "https://inwise-node-functions1.azurewebsites.net/api/get_deals?code=s_NhMeiQKV0sV95UNpqTp2EO1zg2jcS7qNbQFiATs6T0AzFuRxVfhw%3D%3D";
const getUserUrl = 'https://inwise-node-functions1.azurewebsites.net/api/user-get?code=F67bD1MyPw5tlYvVyzDYTniwPLmoAksGFa2-V88OZs0zAzFuw7QbFQ%3D%3D';

function DealsAll() {
  const location = useLocation() as LocationProps
  const dealSummaries = location?.state?.dealSummaries ?? [];
  const onDealSummariesUpdated = location?.state?.onDealSummariesUpdated;
  const [dealSummariesLocal, setDealSummariesData] = useState(dealSummaries);
  const [dealsSubscribed, setDealsSubscribed] = useState([]);

  useEffect(() => {
    if (dealSummariesLocal?.length) {
      return;
    }
    fetch(getDealsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO add JWT
      // TODO: repeated (also in Dashboard)
      // TODO: rename/change to get_deal_summaries?
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
        accessToken: localStorage.getItem("accessToken")
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setDealSummariesData(data);
        onDealSummariesUpdated?.(data)
      })
      .catch((error: Error) => {
        console.log(error);
      });

    fetch(getUserUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken: localStorage.getItem("accessToken") }),
    })
      .then(res => res.json())
      .then(data => {
        setDealsSubscribed(data.user.dealsSubscribed);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  return (
    <Flex
      pt={{ base: '60px', md: '80px', xl: '80px' }}
      display="grid"
      gap="16px"
      gridTemplateColumns={'repeat(auto-fit, minmax(550px, 1fr))'}>
      {dealSummariesLocal.length > 0
        ? <DealsTabContainer dealSummaries={dealSummariesLocal} dealsSubscribed={dealsSubscribed} />
        : "No deals to show"
      }

    </Flex>
  );
}

export default DealsAll;
