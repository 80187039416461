
import { Text, Icon, } from "@chakra-ui/react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";
import { Meeting } from "views/deals/Deal";

export default function MeetingCalendar(props: { meetings: Meeting[], selectedDate: Date, onSelectionChanged: (newValue: Date) => Promise<void>; }) {
  const { meetings, selectedDate, onSelectionChanged } = props

  const mappedMeetingsDates = meetings?.map((meeting: Meeting) => {
    let meetingDate = new Date(meeting.date);
    return `${meetingDate.getFullYear()}-${meetingDate.getMonth()}-${meetingDate.getDate()}`;
  });

  return (
    <>
      <Calendar
        onChange={onSelectionChanged}
        value={selectedDate}
        view={"month"}
        tileContent={<Text color="brand.500" />}
        prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
        tileClassName={({ date }) => {
          let dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
          if (mappedMeetingsDates?.find((val: string) => val === dateString)) {
            if (date > new Date()) {
              return "future-meeting-date-highlight";
            } else if (date < new Date()) {
              return "past-meeting-date-highlight";
            }
          }
        }}
      />
    </>
  )
}
