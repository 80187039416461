import {
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { CompanySearchResult } from 'views/prospects/Prospect';
import FindCompany from './components/FindCompany';
import { useState } from 'react';
import SelectCompany from './components/SelectCompany';
const searchPeopleDataURL = "https://inwise-node-functions1.azurewebsites.net/api/pdl-enrichment-search?code=JlJGCSvRSAgDF08_uWHdQB4JnPyn6vgXHU4kPoodPmhzAzFuK0xBvw%3D%3D";

interface CompanyFormData {
  companyName: string;
}

export function SearchCompany(props: {
  onSelectCompany: (selectedCompany: CompanySearchResult) => void;
  onReturn: () => void;
  [x: string]: any;
}) {

  const { onSelectCompany, onReturn, ...rest } = props;
  const headerColor = useColorModeValue('navy.800', 'white')

  const [companySearchResults, setCompanySearchResults] = useState<CompanySearchResult[]>();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const handleFindCompanySubmit = async (formData: CompanyFormData) => {
    setCompanySearchResults(null)
    setShowSearchResults(true);
    try {
      const response = await fetch(searchPeopleDataURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ command: 'company', name: formData.companyName }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setCompanySearchResults(responseData);
      } else {
        console.error('Error sending data:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <Flex {...rest}>
      {!showSearchResults && <FindCompany onSubmit={handleFindCompanySubmit} onReturn={onReturn} />}
      {showSearchResults && <SelectCompany data={companySearchResults} onSelectCompany={onSelectCompany}
        onReturn={() => { setShowSearchResults(false); setCompanySearchResults(null); }}
        onCancel={() => { setCompanySearchResults(null); onReturn(); }} maxH={'400px'} />}
    </Flex>
  );
}
