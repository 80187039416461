import { Flex, Icon, ListItem, useColorModeValue, Text, useDisclosure, } from '@chakra-ui/react';
import { ThumbsDownIcon, ThumbsUpIcon, } from 'components/icons/Icons';
import { useEffect, useState } from 'react';
import { Insight } from '../../views/deals/Deal'
import { FeedbackPopup } from './FeedbackPopup';

const giveInsightFeedbackURL = "https://inwise-node-functions1.azurewebsites.net/api/insight-feedback?code=6vVFswuOuZjcLzZgaYEJpgiwB1rlYvuCWkqBIH8HqPsPAzFuFajrjQ%3D%3D"

export function InsightFeedbackContainer(props: {
  insight: Insight,
  isPopupOpen: boolean,
  onPopupToggle: (isOpen: boolean) => void,
}) {
  const { insight, isPopupOpen, onPopupToggle, ...rest } = props;
  const [isActive, setIsActive] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleMouseEnter = () => {
    !isPopupOpen && setIsHovered(true);
  };

  const handleMouseLeave = () => {
    !isPopupOpen && setIsHovered(false);
  };

  useEffect(() => {
    onPopupToggle(isOpen);

  }, [isOpen]);

  const handleLikeButtonClicked = async () => {
    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), insightId: insight.insightId, isPositive: true }),
      });
      if (!response.ok) {
        console.error('Feedback successful');
        return;
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
  }
  const handleDislikeButtonClicked = async () => {
    onToggle();
    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), insightId: insight.insightId, isPositive: false }),
      });
      if (!response.ok) {
        console.error('Feedback successful');
        return;
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
  }

  const defineInsightContent = (insight: Insight) => {
    let content;
    const item = insight.content.item;
    if (item) {
      if (insight.content.owner) {
        content = <Text><Text as="strong">{insight.content.owner}</Text> to {item.toLowerCase()}{insight.content.deadline ? `. By ${insight.content.deadline.toLowerCase()}` : '. No deadline defined'}</Text>
      } else if (item.substr(-16) === 'to be calculated') {
        const insightString = item.slice(0, -16).replace(/([A-Z])/g, ' $1').toLowerCase();;
        content = <Text> <Text as="strong">{insightString}</Text>{item.slice(-16)}</Text>
      } else return null;
    } else {
      content = <Text>{typeof insight.content === 'string' ? insight.content : item}</Text>;
    }
    return <ListItem key={insight.insightId} ml={2}>{content}</ListItem>
  }

  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');

  if (!insight || !insight.content) return null;
  return (
    <>
      {isActive && <Flex
        flexDir={'row'}
        flexGrow={1}
        flexBasis={0}
        mr='32px'
        justifyContent={'space-between'}
        {...rest}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        position="relative"
        bg={isOpen ? "#ECECEC" : 'transparent'}
      >
        {defineInsightContent(insight)}
        <Flex position="relative">
          <FeedbackPopup insightId={insight.insightId} isOpen={isOpen} onClose={onClose} onToggle={onToggle} onDismissed={() => setIsActive(false)} />
          {isHovered && !isOpen && (
            <>
              <Flex
                as="button"
                onClick={handleLikeButtonClicked}
                color={textColorLight}
                _hover={{ color: textColorTertiary }}
              >
                <Icon as={ThumbsUpIcon} w="20px" h="20px" />
              </Flex>
              <Flex
                as="button"
                onClick={handleDislikeButtonClicked}
                color={textColorLight}
                _hover={{ color: textColorTertiary }}
                ml='4px'
              >
                <Icon as={ThumbsDownIcon} w="20px" h="20px" />
              </Flex>
            </>
          )}
        </Flex>
      </Flex>}
    </>
  );
}
