import { Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AccountCard from './components/AccountCard';

const getCollectionUrl = "https://inwise-node-functions1.azurewebsites.net/api/get_collection?code=RjvoMmhshVcyKSELKzZCDkqkMo--3PJsRC607T8FrNhAAzFuvEyeUA%3D%3D";

export default function AccountsAll() {
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    if (accounts?.length) return;
    fetch(getCollectionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO add JWT
      body: JSON.stringify({
        collectionName: "Account",
        filter: { wiseCompanyId: localStorage.getItem("wiseCompanyId") },
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log('Accounts: ', data)
        setAccounts(data);
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }, []);

  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      display="grid"
      gap="16px"
      //fix this to make sense with 2 cards
      gridTemplateColumns={'repeat(auto-fit, minmax(550px, 1fr))'}>
      {accounts.length ?
        accounts.map((account, index) => (
          <AccountCard account={account} variant={'allAccounts'} key={account._id} height={'289px'} />
        ))
        : <Text>no accounts</Text>
      }
    </Flex>
  );
}
