import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { SidebarContext } from "contexts/SidebarContext";
import ProspectCard from "../components/ProspectCard";
import AboutContact from "../components/AboutContact";
import AboutAccount from "../components/AboutAccount";
import { Prospect } from "../Prospect";
import { Deal, GetCollectionItemResponse, Meeting } from "views/deals/Deal";
import { MeetingDetailsCard } from "views/deals/overview/components/MeetingDetailsCard";

const getProspectUrl = "https://inwise-node-functions1.azurewebsites.net/api/prospect-item?code=TAXMi59s-1obpgHk7PF1nFlrsGQK8l-bM5ePMCq3z-VPAzFu8oU3cA%3D%3D";

export default function ProspectOverview() {
  let { prospectId } = useParams<{ prospectId: string }>();
  const [prospect, setProspect] = useState<Prospect>();
  const { setBrandText } = useContext(SidebarContext);

  function fetchStuff(url: string, body: string, onResponse: (data: any) => Promise<void>) {
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    })
      .then((res) => {
        res.json().then((data) => {
          onResponse(data)
        });
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (prospect) { return }
    fetchStuff(getProspectUrl, JSON.stringify({ prospectId }), async (data: any) => {
      let response = data as GetCollectionItemResponse;
      setProspect(response.item);
      setBrandText(response.item.name);
    })
  }, [prospectId]);

  if (!prospect) return null;
  const nextMeeting = prospect.meetings.find(m => m.isNext);
  const lastMeeting = prospect.meetings.find(m => m.isLast);

  return (
    <Flex flexDir={"column"}>
      <Flex
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        flexWrap={"wrap"}
        ml={"-30px"}
      >
        <Flex
          mb={"15px"}
          ml={"30px"}
          mr={"-15px"}
          minW={"600px"}
          grow={1}
          shrink={1}
          basis={"600px"}
        >
          <ProspectCard prospect={prospect} variant={"noLinkOverlay"} />
        </Flex>
        <Flex
          ml={"30px"}
          mr={"-15px"}
          minW={"715px"}
          grow={1}
          shrink={1}
          basis={"715px"}
          mb="15px"
        >
          <MeetingDetailsCard
            w={"100%"}
            prospect={prospect}
            variant={'prospectOverview'}
            meetingData={nextMeeting}
            lastMeetingId={lastMeeting?._id}
            height={"289px"}
            pl={"30px"}
          />
        </Flex>
      </Flex>
      <Flex flexWrap={"wrap"} ml={"-30px"}>
        <Flex
          mb={"15px"}
          ml={"30px"}
          mr={"-15px"}
          minW={"850px"}
          grow={1}
          shrink={1}
          basis={"850px"}
        >
          <AboutContact prospect={prospect} h={"100%"} />
        </Flex>
        <Flex
          ml={"30px"}
          mr={"-15px"}
          minW={"465px"}
          grow={1}
          shrink={1}
          basis={"465px"}
        >
          <AboutAccount account={prospect.account} variant={"prospectOverview"} />
        </Flex>
      </Flex>
    </Flex>
  );
}
