import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Flex, HStack, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

export default function IndexPage() {

  const requestDemoUrl = "https://inwise-node-functions1.azurewebsites.net/api/handle-contact-cta?code=LKdc8aEDYZGnrkbeDN7s6vuXnU1m906mORBTqhtWEwAgAzFuHn9N3w%3D%3D";
  const [email, setEmail] = useState('');
  let mainText = '#0F738C';
  const isFormValid = () => {
    return email.trim() !== '';
  };

  const handleRequestDemo = async () => {
    try {
      const response = await fetch(requestDemoUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        console.error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Request demo success:', data);
    } catch (error) {
      console.error('Error requesting demo:', error);
    }
  };

  const textColor = useColorModeValue('gray.400', 'white');
  return (
    <Flex minHeight="100vh" flexDirection="column">
      <NavBar />
      <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} flex="1">
        <Flex alignItems={'flex-start'} justifyContent={'flex-start'} mt='100px' ml='50px' w={'100%'} flexDir='column' pl={'200px'}>
          <Flex>
            <Text fontSize={'50px'}>Scale Sales Effortlessly</Text>
          </Flex>
          <Flex >
            <Text fontSize={'20px'}>Get personalized sales playbooks for every prospect.</Text>
          </Flex>
          <Flex mt='35px'>
            <Input placeholder='Enter your email address' size='lg' w={'300px'} borderWidth={3} value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button fontSize='sm' variant='brand' borderWidth={3} fontWeight='500' w='200px' h='50px' ml='40px' onClick={handleRequestDemo} isDisabled={!isFormValid()}>Request Demo</Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'}>
        <FooterNavBar />
      </Flex>

    </Flex>
  );
}
