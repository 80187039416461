import { Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import ProspectCard from '../components/ProspectCard';

const getProspectsUrl = "https://inwise-node-functions1.azurewebsites.net/api/prospect-list?code=_yqUHjUnEXDKxTvtmvlGbKUR7oMUQeOOLHePYlKKlFfmAzFu36xXWA%3D%3D";
// 'https://www.districtportraits.com/media/photos/linkedin-no-picture-avatar.png'

function ProspectsAll() {
  const [prospects, setProspects] = useState([]);

  useEffect(() => {
    if (prospects?.length) return;
    fetch(getProspectsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO add JWT
      body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId") }),
    })
      .then(res => res.json())
      .then(data => {
        setProspects(data);
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }, []);

  return (
    <Flex
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      display="grid"
      gap="16px"
      //fix this to make sense with 2 cards
      gridTemplateColumns={'repeat(auto-fit, minmax(550px, 1fr))'}>
      {prospects.length ?
        prospects.map((prospect, index) => (
          <ProspectCard
            prospect={prospect}
            variant={"withLinkOverlay"}
            key={index} />
        ))
        : <Text>no prospects</Text>
      }
    </Flex>
  );
}

export default ProspectsAll;
