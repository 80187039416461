import { useState, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import DealsTabContainer from './DealsTabContainer';
import { Deal } from 'views/deals/Deal'

const getDealsUrl = "https://inwise-node-functions1.azurewebsites.net/api/get_deals?code=tsXi6-Lj9GBh7QQ2NcDraQvdEUmzrnCN2WlpRn082-B1AzFurFqIig%3D%3D";
const getUserUrl = 'https://inwise-node-functions1.azurewebsites.net/api/user-get?code=F67bD1MyPw5tlYvVyzDYTniwPLmoAksGFa2-V88OZs0zAzFuw7QbFQ%3D%3D';

export default function Dashboard(props: { dealSummaries: Deal[], onDealSummariesUpdated: Function }) {
  const { dealSummaries, onDealSummariesUpdated } = props;
  const [dealSummariesLocal, setDealSummariesData] = useState(dealSummaries);
  const [dealsSubscribed, setDealsSubscribed] = useState([]);

  useEffect(() => {
    if (dealSummariesLocal) {
      return;
    }
    fetch(getDealsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId") }),
    })
      .then(res => res.json())
      .then(data => {
        setDealSummariesData(data);
        onDealSummariesUpdated?.(data)
      })
      .catch((error: Error) => {
        console.log(error);
      });


    fetch(getUserUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken: localStorage.getItem("accessToken") }),
    })
      .then(res => res.json())
      .then(data => {
        setDealsSubscribed(data.user.dealsSubscribed);
      })
      .catch((error: Error) => {
        console.log(error);
      });

  }, []);

  return (
    <Flex h='100%' w={'100%'}>
      <Box w='100%'>
        <DealsTabContainer dealSummaries={dealSummariesLocal} dealsSubscribed={dealsSubscribed} />
      </Box>
    </Flex>
  );
}
