import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, useColorModeValue, Tab, TabList, TabPanel, TabPanels, Tabs, Select, useToast } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { HSeparator } from 'components/separator/Separator';
import { Deal, Meeting } from 'views/deals/Deal';
import MeetingCard from 'views/deals/overview/components/MeetingCard';
import { getFormattedDateTime } from 'views/deals/overview/components/MeetingDetailsCard';
import Card from 'components/card/Card';

const deleteAccountUrl = "https://inwise-node-functions1.azurewebsites.net/api/user-delete-account?code=lbuuTAmE8pDv2e3fnKPA8BC7fpURbuN23uZjIEulyR0CAzFuSStQgw%3D%3D";

const Settings = () => {

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} w={'75%'} >
      <Tabs variant='unstyled' orientation='vertical' aria-orientation='vertical' h='100vh' pb={'30px'} ml={'10px'}>
        <TabList w={'25%'} minH={'500px'} minW={'250px'} pt={'10px'} borderColor='blackAlpha.300' borderRightWidth='1px'>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} borderTopLeftRadius='16px'>Unassigned meetings</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Delete account</Tab>
        </TabList>
        <TabPanels>
          <TabPanel py={'0px'}>
            <AssignMeetingsTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <DeleteAccountTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box >
  );
}

export default Settings;


function DeleteAccountTab(props: {
  [x: string]: any;
}) {
  const { ...rest } = props;
  const deleteAccountUrl = "https://inwise-node-functions1.azurewebsites.net/api/user-delete-account?code=lbuuTAmE8pDv2e3fnKPA8BC7fpURbuN23uZjIEulyR0CAzFuSStQgw%3D%3D";
  const [email, setEmail] = useState('');

  const isFormValid = () => {
    return email.trim() !== '';
  };

  const handleSubmit = async () => {
    console.log(`Requesting account deletion for ${email}`)
    const accessToken = localStorage.getItem("accessToken");
    fetch(deleteAccountUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken, login: email })
    })
      .then(res => res.json())
      .then(response => {
        console.log(response, response.deleted);
        if (response.deleted) {
          localStorage.clear();
          window.location.replace("/");
        } else {
          if (response.errorMessage) {
            alert(response.errorMessage);
          } else {
            alert('Failure at deleting account');
          }
        }
      });
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Flex>
        <Text color='red' fontSize={'lg'} fontWeight={'700'} >Delete Account</Text>
      </Flex>
      <HSeparator mt='10px' />
      <Flex pt={'20px'} px={'10px'}>
        <Text fontSize={'md'} fontWeight='400' color={textColor}>Are you sure? This action is permanent and <b>CANNOT</b> be undone.</Text>
      </Flex>
      <FormControl pt='25px' px={'10px'} w={'100%'}>
        <Flex>
          <Flex flexDir={'column'} w={'50%'}>
            <FormLabel display='flex' fontSize='md' fontWeight='400' color={textColor} mb='8px'>
              Enter the email linked to your account to confirm:
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <EmailIcon color='gray.300' />
              </InputLeftElement>
              <Input
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='example@email.com'
                mb='24px'
                fontWeight='400'
                size='lg'
                onChange={e => setEmail(e.target.value)}
              />
            </InputGroup>
          </Flex>
        </Flex>
        <Flex justifyContent='flex-end' mt='24px'>
          <Button
            variant={'brand'}
            borderWidth={3}
            fontWeight='400'
            w='200px'
            h='50px'
            onClick={handleSubmit}
            isDisabled={!isFormValid()}>
            Delete Account
          </Button>
        </Flex>
      </FormControl>
    </Box>
  )
}

function AssignMeetingsTab(props: {
  [x: string]: any;
}) {
  const { ...rest } = props;
  const getUnassignedMeetingsUrl = "https://inwise-node-functions1.azurewebsites.net/api/meeting-get-not-assigned?code=uSdFTD5Tud3kkH9qC3Jbnfx76hiCQFXXa9PeuaPUKf8gAzFuhmYSqg%3D%3D"
  const getDealsUrl = "https://inwise-node-functions1.azurewebsites.net/api/get_deals?code=s_NhMeiQKV0sV95UNpqTp2EO1zg2jcS7qNbQFiATs6T0AzFuRxVfhw%3D%3D";
  const assignMeetingToDealUrl = "https://inwise-node-functions1.azurewebsites.net/api/orchestrators/assign-meeting-to-deal-orchestration?code=iusd5FxZevwTh8BJMVdL8NfxiRDJ7PJYCI3MFatm4eoVAzFu91iHFg%3D%3D"

  const [meetings, setMeetings] = useState<Meeting[]>()
  const [deals, setDeals] = useState<Deal[]>()
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
  const [selectedDeal, setSelectedDeal] = useState<Deal>();
  const toast = useToast();

  useEffect(() => {
    if (meetings) {
      return;
    }
    fetch(getUnassignedMeetingsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId") })
    })
      .then(res => res.json())
      .then(data => {
        let meetings = data.meetings as Meeting[];
        setMeetings(meetings);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (deals) {
      return;
    }
    fetch(getDealsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
      }),
    })
      .then(res => res.json())
      .then(data => {
        setDeals(data);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  const handleDealSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const deal = deals.find(deal => deal._id === event.target.value) || null;
    setSelectedDeal(deal)
  }

  const handleSubmit = async () => {

    try {
      const response = await fetch(assignMeetingToDealUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meetingID: selectedMeeting._id, dealID: selectedDeal._id }),
      });
      if (!response.ok) {
        console.error('Failed to reassign meeting');
        toast({
          position: "top",
          title: "Failed to assign meeting",
          description: `Failed to assign "${selectedMeeting.name}" to ${selectedDeal.name}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        return;
      }
      else {
        setMeetings(meetings?.filter(meeting => meeting._id !== selectedMeeting._id));
        toast({
          position: "top",
          title: "Meeting assigned",
          description: `"${selectedMeeting.name}" has been assigned to ${selectedDeal.name}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      }
    }
    catch (error) { console.error('Error during fetch:', error) }

  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  if (!meetings) return null;



  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Flex>
        <Text fontSize={'lg'} fontWeight={'700'} color={textColor} >Assign unassigned meetings to an existing deal</Text>
      </Flex>
      <HSeparator mt='10px' />
      {meetings.length > 0 ? <Flex flexDir={'column'} pt='25px' px={'10px'} w={'100%'}>
        <MeetingCard
          meetings={meetings}
          variant='full'
          onSelectionChanged={function (selectedMeeting: Meeting): void { setSelectedMeeting(selectedMeeting) }}
          mb={'30px'} />
        <Text color={textColor} mb={'20px'} >Selected Meeting: </Text>
        <Flex pl={'10px'} mb={'20px'} >
          {selectedMeeting &&
            <Card py={'10px'} borderRadius={'8px'}>
              <Text color={textColor} >{selectedMeeting.name} {getFormattedDateTime(selectedMeeting)}</Text>
            </Card>}
        </Flex>
        <Text color={textColor} mb={'20px'} >Selected Deal: </Text>
        <Flex pl={'10px'} mb={'20px'} >
          <Select onChange={handleDealSelected} borderRadius={'8px'} placeholder='Select deal'>
            {deals?.map((deal) => (<option key={deal._id} value={deal._id}>{deal.name}</option>))}
          </Select>
        </Flex>
        <Flex justifyContent='flex-end' mt='24px'>
          <Button
            variant={'brand'}
            borderWidth={3}
            fontWeight='400'
            w='200px'
            h='50px'
            onClick={handleSubmit}
            isDisabled={!selectedDeal}
          >
            Assign to deal
          </Button>
        </Flex>
      </Flex> :
        <Flex flexDir={'column'} pt='25px' px={'10px'} w={'100%'}>
          <Text color={textColor} mb={'20px'} >There are currently no unassigned meetings. </Text>
        </Flex>

      }
    </Box>
  )
}
