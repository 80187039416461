import { Flex, Text, useColorModeValue, UnorderedList, ListItem } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';
import { ActionItem } from 'views/deals/Deal';

export default function ActionItems(props: {
  header?: string,
  actionItems: any,
  variant?: string
}) {
  const { actionItems, variant, header, ...rest } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  console.log(actionItems);
  if (!actionItems) return null;

  if (variant === 'recommended') {
    const defineContent = (item: any) => {
      if (item.substr(-15) === 'to be discussed') {
        const insightString = item.slice(0, -16).replace(/([A-Z])/g, ' $1').toLowerCase();;
        const content = <Text> <Text as="strong">{insightString.charAt(0).toUpperCase() + insightString.slice(1)}</Text>{item.slice(-16)}</Text>
        return <ListItem ml={2}>{content}</ListItem>
      }
    }
    return (
      <Card mt='20px' p='20px'>
        <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px'>
          <Flex align='center' textAlign='center' pb='10px'>
            <Text fontWeight={'bold'} color={textColor}>Recommended Action Items</Text>
          </Flex>
          <UnorderedList fontSize={'16px'}>
            {actionItems.map((ai: string) => (
              defineContent(ai)))
            }
          </UnorderedList>
        </Flex>
      </Card>
    )
  }

  return (
    <Card mt='20px' p='20px'>
      <InsightSectionWithFeedback
        insights={actionItems}
        sectionHeader={header}
        {...rest}
      />
    </Card>
  );
}
