import { useState, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/react';

const getGlobalMeetingsUrl = "https://inwise-node-functions1.azurewebsites.net/api/get-global-meetings?code=PeS1gdafHHs2mwNcxTGem47SV8yvFLZ0ljG3MAQ5e2DCAzFuuA6eJQ%3D%3D"

import { Meeting } from 'views/deals/Deal';
import MeetingCard from 'views/deals/overview/components/MeetingCard';
export default function DashboardCalendar() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [meetings, setMeetings] = useState<Meeting[]>()

  useEffect(() => {
    if (meetings?.length) {
      return;
    }

    fetch(getGlobalMeetingsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
        accessToken: localStorage.getItem("accessToken")
      }),
    })
      .then(res => res.json())
      .then(data => {
        let meetings = data as Meeting[];
        setMeetings(meetings);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  if (!meetings) return null;
  return (
    <MeetingCard
      meetings={meetings}
      variant='mini'
      onSelectionChanged={function (selectedMeeting: Meeting): void { }}
      mb={'30px'} />
  );
}
