
import { useEffect, useState } from "react";
import { Prospect } from "views/prospects/Prospect";
import ProspectListCard from "views/deals/overview/components/ProspectListCard"

//TODO (KRIS) : move this to accounts > overview??
export default function AccountProspectList(props: {
  accountId: string,
  accountName: string
  [x: string]: any
}) {
  const { accountId, accountName, ...rest } = props;
  const getProspectsUrl = "https://inwise-node-functions1.azurewebsites.net/api/prospect-list?code=_yqUHjUnEXDKxTvtmvlGbKUR7oMUQeOOLHePYlKKlFfmAzFu36xXWA%3D%3D";
  const [prospects, setProspects] = useState<Prospect[]>([]);

  useEffect(() => {
    if (prospects?.length) return;
    fetch(getProspectsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO add JWT
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
        accountId
      }),
    })
      .then(res => res.json())
      .then(data => {
        setProspects(data);
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }, []);
  return (
    <ProspectListCard
      prospects={prospects}
      headerText={`Prospects at ${accountName}`}
      variant={'accountOverview'}
      w={'100%'}
      {...rest} />
  );
}
